import React, { useState, useEffect } from 'react';
import { Col, Card, Row, Button, Modal } from 'react-bootstrap';
import ProgressBar from '../view/ProgressBar';

const ValuationCard = ({ state, prevPage, nextPage }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(state.error);
    const [tradePriceMin, setTradePriceMin] = useState(null);
    const [tradePriceMax, setTradePriceMax] = useState(null);
    const [vehicleDetails, setVehicleDetails] = useState({
        make: '',
        model: '',
        year: ''
    });

    useEffect(() => {
        console.log('Valuation State', state);
        // Simulate data loading
        if (state.deal) {
            const { trade_price_min, trade_price_max, model, year } = state.deal.car;
            setTradePriceMin(trade_price_min);
            setTradePriceMax(trade_price_max);
            setVehicleDetails({
                make: model.manufacturer.name,
                model: model.name,
                year
            });
            setLoading(false);
            console.log('Found car!');
        }
    }, [state]);

    let content;
    if (loading) {
        content = (
            <div className="center-flex">
                <div>Loading vehicle valuation...</div>
            </div>
        );
    // } else if (error) {
    //     content = (
    //         <div>There was an error retrieving the vehicle information. Please try again.</div>
    //     );
    } else {
        content = (
            <>
                <Col xs={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="card-title">
                                {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                            </Card.Title>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        <p>Estimated Trade-In Range:</p>
                                        <h3>
                                            ${tradePriceMin} - ${tradePriceMax}
                                        </h3>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card> 
                        <Card.Body> 
                            <Card.Title className="card-title"> 
                                What's Next? 
                            </Card.Title>
                            <Card.Text> 
                                <Row> 
                                    <Col> 

                                        Get real, committed bids from our buyer network by completing an inspection! Our app will take you through a fast inspection process that will get you the best price for your vehicle. 
                                        <p></p>
                                        Simply follow the link sent to you via SMS to complete the inspection. 

                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </>
        );
    }

    return (
        <>
            <Modal.Header>
                <Col xs={4}>
                    <Modal.Title>Vehicle Valuation Summary</Modal.Title>
                </Col>
                <Col xs={8}>
                    <ProgressBar pageNumber={state.pageNumber} pages={state.pages} />
                </Col>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ marginTop: '30px' }}>
                    {content}
                </Row>

                <Row className='row-buttons'>
                    <Col className='row-button-left'>
                        <Button
                            type="button"
                            variant='outline-dark'
                            onClick={prevPage}
                        >
                            Go Back
                        </Button>
                    </Col>
                    <Col className='row-button-right'>
                        <Button
                            variant="outline-dark"
                            onClick={nextPage}
                            disabled={!!error}
                        >
                            Next
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </>
    );
};

export default ValuationCard;