import React from 'react' ;

import CustomIcon from '../view/CustomIcon';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ButtonField, TextField } from '../view/ViewElements';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import ProgressBar from '../view/ProgressBar';
import { handleComponentChange } from '../controllers/MainViewController';

function FormPage3(props) {

    const formComplete = (x) => {
        const { tyres, interior_condition, exterior_condition, keys, books, service_history } = x
        return tyres && interior_condition && exterior_condition && keys && books && service_history
    }
    return (
        <>
            <Modal.Header>
                <Col xs={4}>
                    <Modal.Title>Car Details</Modal.Title>
                </Col>
                <Col xs={8}>
                    <ProgressBar pageNumber={props.state.pageNumber} pages={props.state.pages} />
                </Col>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-center" style={{ marginLeft: '10%', marginRight: '10%' }}>
                    <Form onSubmit={props.nextPage} id={props.id}>
                        <div className="button-field-container">
                            {/* <ButtonField
                            label="Estimated Availability"
                            id="estimated_arrival"
                            className="secondary"
                            // options={['Immediate', '3-5 weeks', 'More Than 8 Weeks']}
                            options={['Immediate', 'Within 7 days', '1-2 weeks', '2-3 weeks', '3-5 weeks', '5-8 weeks']}
                            value={props.state.estimated_arrival}
                            onChange={handleComponentChange(props.setState)} /> */}
                            <ButtonField
                                label="Keys"
                                id="keys"
                                options={['1', '2']}
                                value={props.state.keys}
                                onChange={handleComponentChange(props.setState)} />
                            <ButtonField
                                label="Books"
                                id="books"
                                options={['Yes', 'No']}
                                value={props.state.books}
                                onChange={handleComponentChange(props.setState)} />
                            <ButtonField
                                label="Service History"
                                id="service_history"
                                options={['Full', 'Part', 'No']}
                                value={props.state.service_history}
                                onChange={handleComponentChange(props.setState)} />
                        </div>
                        <ButtonField
                            label="Tyres to be Replaced"
                            id="tyres"
                            // options={["0", "1", "2", "3", "4"]}
                            options={['No replacement', '1', '2', '3', '4']}
                            value={props.state.tyres}
                            onChange={handleComponentChange(props.setState)} />
                        <ButtonField
                            label="Interior Condition"
                            id="interior_condition"
                            options={['Below Average', 'Normal Wear and Tear', 'Above Average']}
                            value={props.state.interior_condition}
                            onChange={handleComponentChange(props.setState)} />
                        <ButtonField
                            label="Exterior Condition"
                            id="exterior_condition"
                            options={['Below Average', 'Normal Wear and Tear', 'Above Average']}
                            value={props.state.exterior_condition}
                            onChange={handleComponentChange(props.setState)} />
                        <TextField
                            label="Comments"
                            id="comments"
                            onChange={handleComponentChange(props.setState)}
                            value={props.state.comments}
                            as='textarea'
                            rows={3} />
                    </Form>
                </Row>
                <Row className='row-buttons'>
                    <Col className='row-button-left'>
                        <Button
                            type="button"
                            variant='outline-dark'
                            onClick={props.prevPage}>
                            <CustomIcon icon={faAngleLeft} /> GO BACK
                        </Button>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            type="submit"
                            variant={formComplete(props.state) ? "outline-dark" : "secondary"}
                            disabled={!formComplete(props.state)}
                            form={props.id}>
                            SUBMIT  <CustomIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Row>

            </Modal.Body >
        </>
    )
}


export default FormPage3;