import { useState, useCallback } from 'react';
// npm install dotenv
require('dotenv').config();

console.log('[*] getting params from env file...');
const BACKEND_BASE = "/api"; //process.env.BACKEND_BASE; // "https://cub8p7vqk3.execute-api.ap-southeast-2.amazonaws.com/prod"
console.log('[x] Got BACKEND_BASE =', BACKEND_BASE);




const useDynamo = (initialValue) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getDynamo = useCallback(async (state) => {
        setLoading(true);
        setError(null);
        console.log('ingeststate', state);
        console.log(`${BACKEND_BASE}/leads/${state.widgetId}/${state.phone}`);
        try {
            const lead = await fetch(
                `${BACKEND_BASE}/leads/${state.widgetId}/${state.phone}`,
                {
                    method: 'GET',
                }
            );

            let data;
            console.log('leadok', lead.ok);
            if (!lead.ok) {
                data = false;
            } else {
                data = await lead.json();
                console.log('returnedata', data);
            }
            return data; 
        } catch (error) {
            console.error('Error: ', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    const saveDynamo = useCallback(async (state) => {
        setLoading(true);
        setError(null);
        let deal;
        try {
            console.log('Fetching...', JSON.stringify(state))
            deal = await fetch(
                `${BACKEND_BASE}/leads/${state.widgetId}`,
                {
                    method: 'POST',
                    body: JSON.stringify(state),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Fetched!', deal)

            // if (!deal.ok) {
            //     throw new Error(`HTTP error! status: ${deal.status}`);
            // }

            let dealData = await deal.json();
            console.log(`saveDynamo Deal data>> ${JSON.stringify(dealData)}`);
            console.log("[x] Deal Creation request complete!");
            return dealData;
        } catch (error) {
            console.error('Error:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    return { getDynamo, saveDynamo, loading, error };
};



const loadVehicleData = async (state) =>  {
    try {
        const { rego, regoLocState } = state;
        console.log('Vehicle Data State >>', state);

        console.log("[*] Checking that the vehicle exists....");
        // const response = await fetch(
        //     `${BASE_URL}/v1/redbook/?rego=${rego}&state=${regLocState}`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Authorization': `JWT ${accessToken}`
        //         }
        //     }
        // );


        console.log('vehicle lookup', rego, regoLocState);
        const url = `${BACKEND_BASE}/vlookup/${regoLocState}/${rego}`
        console.log(url);
        let data;
        try { 
            const vehicle = await fetch(
                url,
                {
                    method: 'GET', 
                }
            )
    
            console.log('vehicle', vehicle.ok);
            if (!vehicle.ok) { 
                data = false ;
            }
            else { 
                data = await vehicle.json();
                console.log('returnedata', data);
            }
        }
        catch (error){
            console.error('Error: ',error)
        }

        console.log("[x] Vehicle found!");


        
        // // // // //createClientUser(state);
        // this.createDeal();

        return {
            vehicleData: data,
            loading: false
        };
    } catch (error) {
        console.error("Error loading vehicle data:", error);
        return {
            loading: false,
            error: error.message
        };
    }
}


export { 
    loadVehicleData, 
    useDynamo
};