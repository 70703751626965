import { useState, useEffect } from 'react';
import { useDynamo } from '../util/api';
import { validatePhoneNumber } from '../util/aux';


export const useWidget = (initialValue) => {
    const [state, setState] = useState(initialValue || {}); 
    const { getDynamo, saveDynamo, loading, error, record } = useDynamo(state);

    // Write to dynamo on each page change
    useEffect(() => {
        const runEffect = async () => {
            if (state.phone) {
                if (state.pageNumber) {
                    console.log("Saving to Dynamo...", state);
                    const resp = await saveDynamo(state);
                    if (state.pageNumber === 5) {
                        resp['fetchVal'] = true;
                    }
                    else {
                        resp['fetchVal'] = false;
                    }
                    setState((prevState) => ({...prevState, ...resp}));
                }
            }
        }
        runEffect();
    }, [state.pageNumber]);

    const prevPage = (e) => {
        e.preventDefault();
        setState((prevState) => ({
            ...prevState,
            pageNumber: prevState.pageNumber - 1
        }));
    };

    const nextPage = async (e) => {
        e.preventDefault();
        console.log('event', e);
        let fetchedState;
        if (state.pageNumber === 1) {
            console.log('first page!! Initial state: ', state);
            fetchedState = await getDynamo(state);
            console.log('first page!! fetched state: ', fetchedState);
        }
        if (fetchedState) {
            if (fetchedState.pageNumber === 1) {
                await setState({
                    ...fetchedState, 
                    pageNumber: fetchedState.pageNumber + 1
                });
            }
            else {
                await setState(fetchedState);
            }
        } else {
            console.log('SETTING PAGE NUMBER', state.pageNumber + 1)
            await setState((prevState) => ({
                ...prevState,
                pageNumber: prevState.pageNumber + 1
            }));
            //await saveDynamo(state);
        }
    };

    return { 
        state, setState, prevPage, nextPage
    };
}




export const handleComponentChange = setState => e => {
    const { name, value } = e.target;

    if (name === 'rego') {
        setState((prevState) => ({ ...prevState, [name]: value.toUpperCase() }));
    }
    else if (name === 'carLocPostcode') {
        if (value.length <= 4) {
            setState((prevState) => ({ ...prevState, [name]: value }));
        }
    }
    else if (name === 'phone') {
        let temp = validatePhoneNumber(value);
        setState((prevState) => ({ ...prevState, [name]: temp }));
    }
    else {
        setState((prevState) => ({ ...prevState, [name]: value }));
    }
};

export const handlePatchRequest = async () => {
    return true
}
