import React from 'react';
import ReactDOM from 'react-dom/client';
import MainView from './components/MainView';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

require('dotenv').config(); // load .env

const root = ReactDOM.createRoot(document.getElementById('root'));
export const DEBUG=false

root.render(
    <Router>
      <Routes>
        <Route path="/:widgetId" element={<MainView />} />
        <Route path="/" element={
            <>
            <Modal show={true} backdrop={false}>
              <div className="center-flex">
                <h2>
                    Something went wrong
                </h2>
              </div>
            </Modal>
            </>
        }/>
      </Routes>
    </Router>
)


