import React from 'react';
import CustomIcon from '../view/CustomIcon';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ButtonField, NumberField, TextField } from '../view/ViewElements';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import ProgressBar from '../view/ProgressBar';
import { handleComponentChange } from '../controllers/MainViewController';

function FormPage2(props) {

    const formComplete = (x) => {
        const { carLocPostcode, rego, regoLocState, odometer } = x
        return carLocPostcode && rego && regoLocState && odometer
    }

    return (
        <>
            <Modal.Header>
                <Col xs={4}>
                    <Modal.Title className='display-10'>Car Information</Modal.Title>
                </Col>
                <Col xs={8}>
                    <ProgressBar pageNumber={props.state.pageNumber} pages={props.state.pages} />
                </Col>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-center" style={{ marginLeft: '20%', marginRight: '20%' }}>
                    <Form onSubmit={props.nextPage} id={props.id}>
                        <ButtonField
                            label="State of Registration"
                            id="regoLocState"
                            options={['VIC', 'NSW', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']}
                            value={props.state.regoLocState}
                            onChange={handleComponentChange(props.setState)} 
                            onBlur={() => console.log('changed')}/>
                        <TextField
                            label="Registration Number"
                            id="rego"
                            value={props.state.rego}
                            onChange={handleComponentChange(props.setState)} 
                            onBlur={() => console.log('changed')}/>
                        <TextField
                            label="Car Location Postcode"
                            id="carLocPostcode"
                            value={props.state.carLocPostcode}
                            onChange={handleComponentChange(props.setState)}
                            inputProps={{ maxLength: 4 }} 
                            onBlur={() => console.log('changed')}/>
                        <NumberField
                            label="Odometer (km)"
                            id="odometer"
                            value={props.state.odometer}
                            onChange={handleComponentChange(props.setState)}
                            min="0" 
                            onBlur={() => console.log('changed')}/>
                    </Form>
                </Row>
                {/* <Row className='align-items-right'> */}
                <Row className='row-buttons'>
                    <Col className='row-button-left'>
                        <Button
                            type="button"
                            variant='outline-dark'
                            onClick={props.prevPage}>
                            <CustomIcon icon={faAngleLeft} /> GO BACK
                        </Button>
                    </Col>
                    <Col className='row-button-right'>
                        <Button
                            type="submit"
                            variant={formComplete(props.state) ? "outline-dark" : "secondary"}
                            disabled={!formComplete(props.state)}
                            form={props.id}
                        >
                            NEXT <CustomIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </>
    )
}

export default FormPage2;