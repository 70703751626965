import React from 'react';
import CustomIcon from '../view/CustomIcon';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../view/ViewElements';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import ProgressBar from '../view/ProgressBar';
import { handleComponentChange } from '../controllers/MainViewController';



function FormPage1(props) {

    const formComplete = (x) => {
        const { firstName, lastName, phone } = x        
        return firstName && lastName && phone
    }

    return (
        <>
            <Modal.Header>
                <Col xs={4}>
                    <Modal.Title className='display-10'>Contact Details</Modal.Title>
                </Col>
                <Col xs={8}>
                    <ProgressBar pageNumber={props.state.pageNumber} pages={props.state.pages} />
                </Col>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-center" style={{ marginLeft: '20%', marginRight: '20%' }}>
                    <Form onSubmit={props.nextPage} id={props.id}>
                        <TextField
                            label="First Name"
                            id="firstName"
                            value={props.state.firstName}
                            onChange={handleComponentChange(props.setState)} />
                        <TextField
                            label="Last Name"
                            id="lastName"
                            value={props.state.lastName}
                            onChange={handleComponentChange(props.setState)}
                        />
                        <TextField
                            label="Phone Number"
                            id="phone"
                            value={props.state.phone}
                            onChange={handleComponentChange(props.setState)}
                        />
                    </Form>
                </Row>
                {/* <Row className='align-items-right'> */}
                <Row className='row-buttons'>

                    <Col className='row-button-right'>
                        <Button
                            type="submit"
                            variant={formComplete(props.state) ? "outline-dark" : "secondary"}
                            disabled={!formComplete(props.state)}
                            form={props.id}
                        >
                            NEXT <CustomIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </>
    )
}

export default FormPage1;