import React from 'react';


function FormPage4(props) {
    return (
        <>
            <div className="center-flex">
                <h2>
                    Your listing has been successfully submitted.
                </h2>
                <div>
                    Please follow SMS instructions to download the app and manage your listing.
                </div>
            </div>
        </>

    );
}

export default FormPage4;