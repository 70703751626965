import React from 'react';
import { Card } from 'react-bootstrap';

function VehicleInfoCard(props) {
    return (
        <>
            <Card className="card border-primary mb-3" style={{ width: '60%',  display: 'flex', alignSelf: 'center', marginTop: '20px' }}>
                <Card.Body className="card-header">
                    <Card.Title className="card-title" style ={{marginTop: "10px"}}>
                        {props.year} {String(props.make)} {String(props.model)}
                    </Card.Title>

                    <div style={{ borderTop: '2px solid green', margin: '10px 0 20px' }}></div>           

                    <Card.Text>
                        <p><strong>VARIANT </strong><br/>{props.description}</p>
                        <p><strong>ENGINE </strong><br/>{props.cylinders} Cylinder {props.engine_size}L </p>
                        <p><strong>FUEL TYPE </strong><br/>
                        {String(props.fuel_type).split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                        <p><strong>TRANSMISSION </strong><br/>
                        {String(props.transmission).split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                        
                    </Card.Text>
                </Card.Body>
                <div style ={{margin: '10px 10px', fontWeight: 'stronger'}}>If this is correct, hit 'Next'.</div>
            </Card>            
            
        </>
    );
}

export default VehicleInfoCard;