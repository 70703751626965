import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import "@fontsource/poppins";

import '../style.css';
import 'bootswatch/dist/lux/bootstrap.min.css';
import 'filepond/dist/filepond.min.css';

import VehicleData from './VehicleData';
import ValuationCard from './ValuationCard';
import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import FormPage3 from './FormPage3';
import FormPage4 from './FormPage4';



import { DEBUG } from '../index';
import { Modal, Button, Collapse } from 'react-bootstrap';

import { useWidget } from '../controllers/MainViewController';






// import { prevPage, nextPage, sendRequest, handleComponentChange, handleStateChange } from '../controller/MainViewController';

const MainView = (props) => {
    const { widgetId } = useParams();

    const initValue = {
        pageNumber: 1,
        pages: ['Contact', 'Car Info', 'Overview', 'Details', 'Valuation', 'Done!'],
        // firstName: "",
        // lastName: "",
        // phone: "",
        // regoLocState: "",
        // rego: "",
        // carLocPostcode: "",
        // odometer: "",
        widgetId: widgetId, 
        estimated_arrival: "immediate",
        // tyres: "",
        // interior_condition: "",
        // exterior_condition: "",
        // keys: "",
        // books: "",
        // service_history: "",
        // comments: "",
        min_price: 0,
        // deal_uuid: ""
    };

    const {state, setState, nextPage, prevPage} = useWidget(initValue);

    let fragment;
    let styling;
    if (!widgetId) {
        fragment = <>
              <div className="center-flex">
                <h2>
                    Something went wrong
                </h2>
            </div>
        </>
        styling = 'p2-style';
    }
    else {
        switch (state.pageNumber) {
            case 1:
                fragment = <FormPage1 id='form-page-1' state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />;
                styling = 'p2-style';
                break;
            case 2:
                fragment = <FormPage2 id='form-page-1' state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />;
                styling = 'p2-style';
                break;
            case 3:
                fragment = <VehicleData state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />;
                styling = 'p2-style';
                break;
            case 4:
                fragment = <FormPage3 id='form-page-2' state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />;
                styling = 'p2-style';
                break;
            case 5:
                //fragment = <FileUpload state={state} txFunc={nextPage} prvFunc={prevPage} />;
                fragment = <ValuationCard state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />
                styling = 'p2-style';
                break;
            default:
                fragment = <FormPage4 state={state} setState={setState} nextPage={nextPage} prevPage={prevPage} />;
        }
    }

    return (
        <>
            <Modal show={true} backdrop={false} dialogClassName={styling}>
                {fragment}
                {DEBUG ? <pre><DebugOutput debug={JSON.stringify(state, null, '  ')} /></pre> : null}
            </Modal>
        </>
    );
};



function DebugOutput(props) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="debug-collapsible"
                aria-expanded={open}
            >
                Debug
            </Button>
            <Collapse in={open}>
                <div id="debug-collapsible">
                    {props.debug}
                </div>
            </Collapse>
        </>
    )
}


export default MainView;