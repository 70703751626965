import React from 'react';
import { Col, Row } from 'react-bootstrap';

function ProgressBar(props) {
    const colouriseBadges = function (pages, pageNumber) {
        return pages.map((p, i) => (
            <Col key={`badge-${i}`}>
                <span className={"badge rounded-pill " + (i < pageNumber ? "bg-danger" : "bg-light" )}> 
                    {p}
                </span>
            </Col>
        ));
    };

    return (
        <Col style={{ display: "grid" }}>
            <div style={{ gridRow: 1, gridColumn: 1 }}>
                <hr style={{
                    display: 'block',
                    marginBefore: "0.8em",
                    marginAfter: "0.8em",
                    marginStart: "auto",
                    marginEnd: "auto",
                    overflow: "hidden",
                    borderStyle: "inset",
                    borderWidth: "0.2px",
                    borderColor: "black", 
                    width: "90%",
                    color: "white",
                }} />
            </div>
            <div style={{ paddingTop: "0.5%", width: "100%", zIndex: 1100, gridRow: 1, gridColumn: 1 }}>
                <Row style={{ display: "flex" }}>{colouriseBadges(props.pages, props.pageNumber)}
                </Row>
            </div>
        </Col>
    );
}

export default ProgressBar;