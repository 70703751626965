import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// wrap with memo to prevent unnecessary re-renders
const CustomIcon = React.memo(({ icon, size = '1x', ...props }) => {
    return <FontAwesomeIcon icon={icon} size={size} {...props} />;
});

CustomIcon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    size: PropTypes.string,
};

export default CustomIcon;